<template>
  <div class="py-2 px-6">
    <div v-if="loading" />

    <!-- <div class="flex items-center justify-between my-4">
      <div class="text-lg">Members</div>
      <div>
        <base-button
          wide="py-2"
          icon="UserPlus"
          responsive
          class="text-brand-blue bg-white border-brand-blue"
          color="transparent"
          @click="openMemberInvite"
          >Invite</base-button
        >
      </div>
    </div> -->
    <div class="flex items-centers py-4 pl-2 pr-4">
      <div class="text-xl pr-4 pt-1">{{ $t('team.members.members') }}</div>
      <div class="relative">
        <base-input-text
          v-model="q"
          inner-class="base-input--gray"
          :placeholder="$t('team.placeholder')"
          icon-fa="search"
          type="text"
          class="input-pr-8"
          style="background: #F2F3F4;"
        />
        <div v-if="q" class="icon--clear" @click="q = ''">
          <base-icon size="14" name="close">
            <icon-close />
          </base-icon>
        </div>
      </div>
      <div class="flex-1 text-right">
        <base-button
          v-if="!isUserViewer"
          wide="py-2"
          icon="UserPlus"
          responsive
          class="text-brand-blue bg-white border-brand-blue"
          color="transparent"
          @click="openMemberInvite"
          >{{ $t('team.members.invite') }}</base-button
        >
      </div>
    </div>

    <!-- tab -->
    <div class="flex space-x-4">
      <div
        v-for="(t, i) in tabs"
        :key="`tab-${i}`"
        class="px-4 py-1 cursor-pointer"
        :class="{ 'border-b-2 border-blue-500': tabIndex == i }"
        @click="tabIndex = i"
      >
        {{ t.name }}
      </div>
    </div>
    <div v-if="pendingNotFound" class="flex justify-center align-middle">
      <div class="px-8 pt-20">
        <div class="flex justify-center">
          <logo-member-pending-not-found />
        </div>

        <div class="mt-4 mb-2  text-pending">
          {{ $t('team.members.pendingNotFound') }}
        </div>
      </div>
    </div>
    <table v-else class="w-full my-3">
      <thead class="text-gray-caption text-sm uppercase">
        <tr>
          <th class="font-normal" />
          <th class="font-normal text-left">{{ $t('team.members.name') }}</th>
          <th class="font-normal text-left">{{ $t('team.members.bio') }}</th>
          <th class="font-normal">{{ $t('team.members.status') }}</th>
          <th class="font-normal">{{ $t('team.members.role') }}</th>
          <th class="font-normal">{{ $t('team.members.more') }}</th>
        </tr>
      </thead>
      <tbody class="border border-gray-200">
        <tr
          v-for="u in currentMembers"
          :key="`member-${u.id}`"
          class="border-b border-gray-200 py-2"
        >
          <td class="p-2 w-16">
            <img
              :src="(u.user && u.user.profilePicture) || defaultImageSrc"
              class="w-10 h-10 object-cover rounded-full"
            />
          </td>
          <td>
            <div v-if="u.status == TeamMemberStatus.Pending">
              <v-clamp autoresize :max-lines="1" class="max-w-xs">
                {{ u.inviteToEmail }}
              </v-clamp>
            </div>
            <div v-else>
              <v-clamp autoresize :max-lines="1" class="max-w-xs">
                {{ u.user.name }}
              </v-clamp>
              <v-clamp
                autoresize
                :max-lines="1"
                class="max-w-xs text-sm text-gray-500"
              >
                {{ u.user.email }}
              </v-clamp>
            </div>
          </td>
          <td>
            <v-clamp autoresize :max-lines="3" class="max-w-xs">
              {{ u.user && u.user.bio ? u.user.bio : '-' }}
            </v-clamp>
          </td>
          <td class="text-center p-2">
            <div
              v-if="u.status == TeamMemberStatus.Pending"
              class="status--awaiting-response  py-1 rounded-full w-32 text-xs mx-auto	"
            >
              {{ $t('team.members.pending') }}
            </div>
            <div
              v-else-if="u.status == TeamMemberStatus.Active"
              class="status--active py-1 rounded-full text-xs mx-auto w-32"
            >
              {{ $t('team.members.active') }}
            </div>
          </td>
          <td class="text-center text-gray-500 text-sm">
            <div v-if="u.role == TeamMemberRole.Editor">
              {{ $t('team.members.editor') }}
            </div>
            <div v-if="u.role == TeamMemberRole.Admin">
              {{ $t('team.members.admin') }}
            </div>
            <div v-if="u.role == TeamMemberRole.Viewer">
              {{ $t('team.members.viewer') }}
            </div>
          </td>
          <td>
            <select-team-role
              v-if="
                (u.isCurrentUser && !isAdmin) ||
                  (isAdmin && countAdmin > 1) ||
                  (!u.isCurrentUser && isAdmin)
              "
              :key="`select-role-${u.id}-${countAdmin}`"
              :member="u"
              :is-dot-button="true"
              :show-role="
                (isAdmin && !u.isCurrentUser) || (isAdmin && countAdmin > 1)
              "
              @removeUser="onKickMember(u)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <modal-team-invite />
  </div>
</template>

<script>
import VClamp from 'vue-clamp';
import ModalTeamInvite from '@/components/modal/modal-team-invite';
import { BoardMember } from '@/types/Board';
import { Team, TeamMemberStatus, TeamMemberRole } from '@/types/Team';
import SelectTeamRole from '@/components/select/select-team-role';
import DialogConfirm from '@/components/board/board-dialog-confirm.vue';
import IconClose from '@/components/icons/IconClose';
import LogoMemberPendingNotFound from '@/components/icons/LogoMemberPendingNotFound';

export default {
  components: {
    VClamp,
    ModalTeamInvite,
    SelectTeamRole,
    IconClose,
    LogoMemberPendingNotFound
  },
  name: 'Teams-Member',
  data() {
    return {
      loading: false,
      tabIndex: 0,
      q: ''
    };
  },
  watch: {
    '$route.params.id'() {
      this.q = '';
      this.fetch();
    }
  },
  async mounted() {
    this.fetch();
  },
  computed: {
    TeamMemberStatus() {
      return TeamMemberStatus;
    },
    TeamMemberRole() {
      return TeamMemberRole;
    },
    pendingNotFound() {
      return this.tabIndex == 2 && !this.currentMembers.length;
    },
    tabs() {
      return [
        {
          name: `${this.$t('team.members.all')} (${this.members.length})`,
          members: this.members
        },
        {
          name: `${this.$t('team.members.active')} (${
            this.membersActive.length
          })`,
          members: this.membersActive
        },
        {
          name: `${this.$t('team.members.pending')} (${
            this.membersPending.length
          })`,
          members: this.membersPending
        }
      ];
    },
    members() {
      if (this.q)
        return this.$store.getters['teams/getMembers'].filter(
          f =>
            (f.user &&
              f.user.name.toLowerCase().search(this.q.toLowerCase()) >= 0) ||
            (f.user &&
              f.user.email.toLowerCase().search(this.q.toLowerCase()) >= 0) ||
            (f.inviteToEmail &&
              f.inviteToEmail.toLowerCase().search(this.q.toLowerCase()) >= 0)
        );
      else return this.$store.getters['teams/getMembers'];
    },
    membersActive() {
      if (this.q)
        return this.$store.getters['teams/getMembersActive'].filter(
          f =>
            (f.user &&
              f.user.name.toLowerCase().search(this.q.toLowerCase()) >= 0) ||
            (f.user &&
              f.user.email.toLowerCase().search(this.q.toLowerCase()) >= 0) ||
            (f.inviteToEmail &&
              f.inviteToEmail.toLowerCase().search(this.q.toLowerCase()) >= 0)
        );
      else return this.$store.getters['teams/getMembersActive'];
    },
    membersPending() {
      if (this.q)
        return this.$store.getters['teams/getMembersPending'].filter(
          f =>
            (f.user &&
              f.user.name.toLowerCase().search(this.q.toLowerCase()) >= 0) ||
            (f.user &&
              f.user.email.toLowerCase().search(this.q.toLowerCase()) >= 0) ||
            (f.inviteToEmail &&
              f.inviteToEmail.toLowerCase().search(this.q.toLowerCase()) >= 0)
        );
      else return this.$store.getters['teams/getMembersPending'];
    },
    countAdmin() {
      const users = this.$store.getters['teams/getMembers'];
      const admins = users.filter(u => u.role == TeamMemberRole.Admin);
      return admins.length;
    },
    currentMembers() {
      return this.tabs[this.tabIndex].members;
    },
    isAdmin() {
      const currentMember = this.members.find(u => u.isCurrentUser);
      return currentMember?.role === TeamMemberRole.Admin;
    },
    team() {
      return this.$store.getters['teams/getTeam'];
    },
    defaultImageSrc() {
      return `https://storage.googleapis.com/workkami-files-dev/statics/f6dd2895acbe4cf4b6d2efa5f279855f_thumb.png`;
    },
    isUserViewer() {
      return this.$store.getters['teams/isUserViewer'];
    }
  },
  methods: {
    async fetch() {
      await this.$store.dispatch('teams/clearMembers');
      await this.$store.dispatch('teams/fetchMembers', this.$route.params.id);
    },
    openMemberInvite() {
      this.$modal.show('invite-team');
    },
    onKickMember(member) {
      const teamName = this.team.name || '';
      this.$modal.show(
        DialogConfirm,
        {
          title: member.isCurrentUser
            ? this.$t('team.buttonConfirm.leave.title')
            : this.$t('team.buttonConfirm.remove.title'),
          description: member.isCurrentUser
            ? `${this.$t(
                'team.buttonConfirm.leave.description1'
              )}"<strong>${teamName}</strong>"${this.$t(
                'team.buttonConfirm.leave.desctiption2'
              )}`
            : `${this.$t('team.buttonConfirm.remove.description1')} "<strong>${
                member.user ? member.user.name : member.inviteToEmail
              }</strong>"?`,
          color: 'text-red-600',
          button: member.isCurrentUser
            ? this.$t('team.buttonConfirm.leave.name')
            : this.$t('team.buttonConfirm.remove.name'),
          confirmAction: 'teams/kickMember',
          confirmData: member
        },
        {
          height: 'auto',
          width: 434,
          scrollable: true
        }
      );
    }
  }
};
</script>

<style scoped>
.icon--clear {
  @apply px-3 absolute right-0 inset-y-0 cursor-pointer z-10 inline-flex place-items-center;
}
.status--active {
  color: #88b864;
  background-color: #eaf5e3;
}
.status--awaiting-response {
  color: #e86b1a;
  background-color: #fef0e3;
}
.text-pending {
  @apply text-center font-medium text-3xl;
}
</style>
